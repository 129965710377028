// color variants
@import 'themes-vars.module.scss';

// third-party
@import ' ~react-perfect-scrollbar/dist/css/styles.css';
@import ' ~slick-carousel/slick/slick.css';
@import ' ~slick-carousel/slick/slick-theme.css';

@import ' ~react-18-image-lightbox/style.css';
@import 'react-responsive-carousel/lib/styles/carousel.min.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| TABLE TOOLBAR ||============================== //
.css-wsew38 {
    justify-content: end;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

.info-page::-webkit-scrollbar {
    width: 0.4em;
}

.info-page::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00)
}

.info-page::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .3);
    // outline: 1px solid slategrey;
    border-radius: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {

        &:hover>.ps__thumb-y,
        &:focus>.ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }

    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container>.ps {
    &.ps--active-y>.ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;

        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }

    &.ps--scrolling-y>.ps__rail-y,
    &.ps--scrolling-x>.ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bounce {

    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }

    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {

    0%,
    50%,
    100% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-10px);
    }

    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {

    0%,
    50%,
    100% {
        transform: translateX(0px);
    }

    25% {
        transform: translateX(-10px);
    }

    75% {
        transform: translateX(10px);
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

.preBuildDashBoard-slider {
    overflow: hidden;

    .slider {
        .slide {
            opacity: 0.5;
            display: flex;
            justify-content: center;

            .custom-slider {
                gap: 40px;
            }

            &.selected {
                opacity: 1;

                .custom-slider {
                    display: flex;
                    flex-direction: column;
                }
            }

            &:not(.selected) {
                transform: scale(0.7);
                transform-origin: top;
            }
        }
    }
}

.project-info {
    .project-slider {
        .slick-list {
            padding: 0 !important;
        }
    }

    .slick-slide {
        opacity: 0.05;
    }

    .slick-active {
        opacity: 0.2;
    }

    .slick-current {
        opacity: 1 !important;

        .MuiTypography-root {
            color: $primaryMain;
        }
    }
}

.ril__inner {
    direction: ltr;
}

.ReactModal__Overlay {
    z-index: 99999 !important;
}

// 
.ql-editor {
    min-height: 150px !important;
}

.css-ksxqxv {
    margin-top: 90px !important;
}
// .css-kuaora-MuiDrawer-docked .MuiDrawer-paper {
//     width: 85px !important;
// }

// margin-top: 60px;
// }

// .css-kuaora-MuiDrawer-docked .MuiDrawer-paper{
//     width: 90px ;
// }
// .css-kuaora-MuiDrawer-docked .MuiDrawer-paper {
//     width: 90px !important;
// }

// .css-pp5p9o-MuiToolbar-root {
//     // background-color: #455f95 ;

// }

// .css-kuaora-MuiDrawer-docked .MuiDrawer-paper {
//     width: 85px;     
// }

//Change the hover colour of the sidebar icon
// .css-1jopxmw-MuiButtonBase-root-MuiListItemButton-root:hover .MuiListItemIcon-root {
//     color: black;
// }

// .css-103f2pi-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
//     // background: #455f95 ;
// }

// .scrollbar-container {
//     // background: #455f95 ;
// }

// .css-rs0zx6{
//     background: #455f95;

// }

// .css-8qbfim {
//     background-color: #ffffff ;
//     margin-right: 0px ;

// }
